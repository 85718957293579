import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

export default function BookList({ data }) {
    const posts = data.allMysqlLectura.edges
    const {
      currentPage,
      pageCount,
      perPage,
      hasPreviousPage,
      hasNextPage
    } = data.allMysqlLectura.pageInfo

    return (
      <Layout>
        {posts.map(({ node }) => {
          return (<div key={node.mysqlId}>
              <Link to={`/book/${node.mysqlId}`}>
                <div>
                  <img style={{maxWidth:'150px'}} src={`data:image/png;base64,${node.portada}`}/>
                </div>
                <div>
                  {node.titulo}
                </div>
              </Link>
            </div>)

        })}
        <div>
          {Array.from({length:pageCount}).map((_, i)=>{
            i=i+1
            const to = i==1 ? `/list` : `/list/${i}`
            return (<Link to={to}>{i} </Link>)
          })}
        </div>
      </Layout>
    )
}

export const blogListQuery = graphql`
  query bookListQuery($skip: Int!, $limit: Int!) {
    allMysqlLectura(
      limit: $limit
      skip: $skip
    ) {
    edges {
      node {
        mysqlId
        titulo
        portada
      }
    }
    pageInfo {
      currentPage
      pageCount
      perPage
      itemCount
      hasPreviousPage
      hasNextPage
      totalCount
    }
  }
}
`